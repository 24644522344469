<template>
<div>
    <Header />
    <div class="banner bg-dark text-white">
            <div class="container">
                <div class="row">
                    <div class="col py-5">
                        <h1><b>Partner</b></h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="py-10 bg-1">
            <div class="container">
                <div class="row row-cols-1 row-cols-md-2 mb-5">
                    <div class="col text-center mb-4 mb-md-0">
                        <img src="@/assets/img/brigadas4.jpg" alt="Donativos" class="img-fluid shadow rounded">
                    </div>
                    <div class="col d-flex align-items-center">
                        <div>
                            <h2 class="pb-3 mb-4"><b>Donation options</b></h2>
                            <ul>
                                <li>Toilet paper</li>
                                <li>Sanitary towels</li>
                                <li>Body bar soap</li>
                                <li>Phone cards</li>
                                <li>Wipes</li>
                                <li>Diapers from 0 to 3 years</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="apoyo" class="row row-cols-1 row-cols-md-2 pt-md-5 formulario">
                    <div class="col order-2 order-md-1">
                            <h2 class="pb-3 mb-4"><b>Volunteering</b></h2>
                        <ul class="nav nav-pills mb-4" id="myTab" role="tablist">
                            <li class="nav-item mr-2" role="presentation">
                                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">VOLUNTEERS</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">SOCIAL SERVICE</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <p class="mb-5">Volunteers interested in supporting the activities of <b>Fundacion Rebeca Lan</b> must provide the following information to be contacted.</p>
                                <form>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Name</label>
                                            <input type="text" class="form-control" placeholder="Name">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Surname</label>
                                            <input type="text" class="form-control" placeholder="Surname">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label>Date of birth</label>
                                            <input type="date" class="form-control" placeholder="dd/mm/aa">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>Occupation</label>
                                            <input type="text" class="form-control" placeholder="Occupation">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Nationality</label>
                                            <input type="text" class="form-control" placeholder="Nationality">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Place of residence</label>
                                            <input type="text" class="form-control" placeholder="Place of residence">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Phone number</label>
                                            <input type="tel" class="form-control" placeholder="Phone number">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Email</label>
                                            <input type="email" class="form-control" placeholder="Email">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Social media</label>
                                            <input type="text" class="form-control" placeholder="Social media">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Place of residence</label>
                                            <input type="text" class="form-control" placeholder="Place of residence">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="sr-only">Ways you would like to support</label>
                                        <input type="text" class="form-control" placeholder="Ways you would like to support">
                                    </div>
                                    <div class="form-group">
                                        <label class="sr-only">Why do you want to become a volunteer for Fundacion rebeca Lan?</label>
                                        <textarea class="form-control" rows="3" placeholder="Why do you want to become a volunteer for Fundacion rebeca Lan?"></textarea>
                                    </div>
                                    <button type="button" class="btn btn-secret">SEND</button>

                                </form>
                            </div>
                            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <form>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Name</label>
                                            <input type="text" class="form-control" placeholder="Name">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Surname</label>
                                            <input type="text" class="form-control" placeholder="Surname">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label>Date of birth</label>
                                            <input type="date" class="form-control" placeholder="dd/mm/aa">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>School</label>
                                            <input type="text" class="form-control" placeholder="School">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Area of study</label>
                                            <input type="text" class="form-control" placeholder="Area of study">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Area of Major</label>
                                            <input type="text" class="form-control" placeholder="Area of Major">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Phone number</label>
                                            <input type="tel" class="form-control" placeholder="Phone number">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Email</label>
                                            <input type="email" class="form-control" placeholder="Email">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Social media</label>
                                            <input type="text" class="form-control" placeholder="Social media">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Place of residence</label>
                                            <input type="text" class="form-control" placeholder="Place of residence">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="sr-only">Availability (days and hours)</label>
                                        <input type="text" class="form-control" placeholder="Availability (days and hours)">
                                    </div>
                                    <div class="form-group">
                                        <label class="sr-only">Why do you want to do your social service in Fundacion rebeca Lan?</label>
                                        <textarea class="form-control" rows="3" placeholder="Why do you want to do your social service in Fundacion rebeca Lan?"></textarea>
                                    </div>
                                    <button type="button" class="btn btn-secret">SEND</button>

                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col order-1 order-md-2 text-center mb-4 mb-md-0">
                        <img src="@/assets/img/voluntariado.jpg" alt="Súmate" class="img-fluid shadow rounded">
                    </div>
                </div>
            </div>
        </div>
    <Footer />
</div>
</template>

<style lang="less">
    @import "@/assets/css/colors.less";
    @import "@/assets/css/main.less";
    @import "./css/nosotros.less";
    .bg-dark{
        overflow: hidden;
        position: relative;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
        text-shadow: 0 1px 15px rgba(0,0,0,.8);

        &::before {
            background-image: url('../../../assets/img/bg-image.jpg');
            content: "";
            background-size: cover;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0.35;
        }
    }
</style>

<script>
import Header from '@/modules/en/Templates/Header'
import Footer from '@/modules/en/Templates/Footer'

export default {
    name: "Nosotros",
    components:{
        Header,
        Footer
    },
    mounted(){
       if(window.location.hash) {
            const id = window.location.hash.substring(1);
            const yourElement = document.getElementById(id);
            const y = yourElement.getBoundingClientRect().top + window.pageYOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }
}
</script>

